/**
 * Utility component used to declaratively show/hide elements
 */
export const Hidden = ({
  visible,
  children,
}: {
  children: React.ReactNode
  visible: boolean
}) => {
  if (!visible) return null
  return <>{children}</>
}
