import React, { useMemo } from 'react'

type SpacingStyles = {
  m?: number
  mb?: number
  ml?: number
  mr?: number
  mt?: number
  mx?: number
  my?: number
  p?: number
  pb?: number
  pl?: number
  pr?: number
  pt?: number
  px?: number
  py?: number
}

const stylesLookup = {
  m: 'margin',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mr: 'marginRight',
  mt: 'marginTop',
  mx: 'margin',
  my: 'margin',
  p: 'padding',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  pr: 'paddingRight',
  pt: 'paddingTop',
  px: 'padding',
  py: 'padding',
}

/**
 * Utility hook to build up spacing styles like margin
 * and padding
 * Allows us to use props like `m`, `p`, `mb`, `px`, etc in our components
 * to control the spacing without having to write any CSS
 * @param {object} options.spacingStyles object representing spacing shorthands
 * @param {object} options.otherStyles object representing all other style overriddes
 * @returns {object} returns a style object that can be passed to a React element
 */
const useSpacing = (
  spacingStyles: SpacingStyles,
  otherStyles?: React.CSSProperties
): React.CSSProperties => {
  const { m, mb, ml, mr, mt, mx, my, p, pb, pl, pr, pt, px, py } = spacingStyles
  const generatedStyles = useMemo<SpacingStyles>(() => {
    const spacingProps = {
      m,
      mb,
      ml,
      mr,
      mt,
      mx,
      my,
      p,
      pb,
      pl,
      pr,
      pt,
      px,
      py,
    }
    const spacingStyles = Object.entries(spacingProps).reduce(
      (acc, [key, value]) => {
        if (!!value) {
          const styleProp = stylesLookup[key]
          if (key.includes('x')) {
            acc[`${styleProp}Left`] = `${value}rem`
            acc[`${styleProp}Right`] = `${value}rem`
          } else if (key.includes('y')) {
            acc[`${styleProp}Bottom`] = `${value}rem`
            acc[`${styleProp}Top`] = `${value}rem`
          } else {
            acc[styleProp] = `${value}rem`
          }
        }
        return acc
      },
      {}
    )
    return spacingStyles
  }, [m, mb, ml, mr, mt, mx, my, p, pb, pl, pr, pt, px, py])

  return {
    ...generatedStyles,
    ...otherStyles,
  }
}

export default useSpacing
