import { BaseTypography } from 'components/design-system/type-system'
import { useDrawer } from 'components/design-system/drawer'
import s from './styles.module.css'

interface DrawerSectionTitleProps {
  children: React.ReactNode
}

export const DrawerSectionTitle = ({ children }: DrawerSectionTitleProps) => {
  const { variant } = useDrawer()

  if (variant === 'rail') return null
  return (
    <div className={s.drawerSectionTitleRoot}>
      <BaseTypography className={s.drawerSectionTitle} color="white" uppercase>
        {children}
      </BaseTypography>
    </div>
  )
}
