import { CSSProperties, useMemo } from 'react'
import cn from 'classnames'
import { css } from '@emotion/css'
import { generateCSS } from 'utilities/styles'

// https://codepen.io/enxaneta/full/adLPwv
type AlignItems = 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'

type AlignContent =
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'center'
  | 'space-around'
  | 'stretch'

type Display = 'flex' | 'inline-flex'

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse'

type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse'

export type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'center'
  | 'space-around'
  | 'space-evenly'

type Overflow = 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto'

interface FlexProps {
  /**
   * Any valid value for the flexbox align-items property
   */
  alignItems?: AlignItems
  /**
   * Any valid value for the flexbox align-content property
   */
  alignContent?: AlignContent
  children: React.ReactNode
  /**
   * Optional CSS class name to pass to the root component
   */
  className?: string
  /**
   * Any valid value for the flexbox display property
   */
  display?: Display
  /**
   * Any valid value for the flexbox flex-direction property
   */
  flexDirection?: FlexDirection
  /**
   * Any valid value for the flexbox flex-wrap property
   */
  flexWrap?: FlexWrap
  /**
   * Any valid value for the flexbox justify-content property
   */
  justifyContent?: JustifyContent
  /**
   * A spacing value representing the amount of space between
   * flex children
   */
  gap?: number
  /**
   * Number representing a rem value for the margin
   */
  m?: number
  /**
   * Number representing a rem value for the bottom margin
   */
  mb?: number
  /**
   * Number representing a rem value for the left margin
   */
  ml?: number
  /**
   * Number representing a rem value for the right margin
   */
  mr?: number
  /**
   * Number representing a rem value for the top margin
   */
  mt?: number
  /**
   * Number representing a rem value for the left and right margin
   */
  mx?: number
  /**
   * Number representing a rem value for the top and bottom margin
   */
  my?: number
  /**
   * Optional min width value to pass to the component root
   */
  minWidth?: number
  /**
   * Number representing a rem value for the padding
   */
  p?: number
  /**
   * Number representing a rem value for the bottom padding
   */
  pb?: number
  /**
   * Number representing a rem value for the left padding
   */
  pl?: number
  /**
   * Number representing a rem value for the right padding
   */
  pr?: number
  /**
   * Number representing a rem value for the top padding
   */
  pt?: number
  /**
   * Number representing a rem value for the left and right padding
   */
  px?: number
  /**
   * Number representing a rem value for the top and bottom padding
   */
  py?: number
  /**
   * Number or string representing the width of the flex container
   */
  width?: number | string
  /**
   * Any valid value for the overflow property
   */
  overflow?: Overflow
  /**
   * Any valid value for the overflow property
   */
  style?: CSSProperties
}

/**
 * Generic, lightweight component that makes it easy to implement
 * a flex container for most basic use cases
 */
export const Flex = ({
  alignItems,
  children,
  className: providedClassName,
  display = 'flex',
  flexDirection,
  flexWrap,
  justifyContent,
  gap,
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  minWidth,
  overflow,
  p,
  pb,
  pl,
  pr,
  pt,
  px,
  py,
  width,
  style,
}: FlexProps) => {
  const className = useMemo(() => {
    const cssRules = generateCSS({
      alignItems,
      display,
      flexDirection,
      flexWrap,
      justifyContent,
      gap,
      m,
      mb,
      ml,
      mr,
      mt,
      mx,
      my,
      minWidth,
      p,
      pb,
      pl,
      pr,
      pt,
      px,
      py,
      width,
      overflow,
    })
    return css`
      ${cssRules}
    `
  }, [
    alignItems,
    display,
    flexDirection,
    flexWrap,
    justifyContent,
    gap,
    m,
    mb,
    ml,
    mr,
    mt,
    mx,
    my,
    minWidth,
    p,
    pb,
    pl,
    pr,
    pt,
    px,
    py,
    width,
    overflow,
  ])

  return (
    <span className={cn(className, providedClassName)} style={style}>
      {children}
    </span>
  )
}
