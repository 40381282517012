import cx from 'classnames'

import s from './styles.module.css'

type CardProps = {
  className?: string
  children?: React.ReactNode
  /**
   * Level of elevation that should be applied to the card
   */
  elevation?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export const Card = ({ className, children, elevation = 'xs' }: CardProps) => {
  const elevationClass = elevation === 'none' ? '' : s[`elevation-${elevation}`]
  return <div className={cx(s.root, elevationClass, className)}>{children}</div>
}
