import cn from 'classnames'
import s from './styles.module.css'

interface DividerProps {
  className?: string
  darkMode?: boolean
  direction?: 'horizontal' | 'vertical'
}

export const Divider = ({
  className,
  darkMode = false,
  direction = 'horizontal',
}: DividerProps) => {
  return (
    <hr
      className={cn(
        s.dividerRoot,
        { [s.darkMode]: darkMode },
        s[direction],
        className
      )}
    />
  )
}
