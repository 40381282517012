import React from 'react'
import cn from 'classnames'

import { Body } from 'components/design-system/type-system/body'
import { Hidden } from 'components/design-system'

import s from './styles.module.css'

type AvatarTextSizes = 24 | 32 | 48
type AvatarImageSizes = 24 | 32 | 48 | 64 | 80 | 112

interface AvatarCommonProps {
  /**
   * Optional CSS class name
   */
  className?: string
  /**
   * Optional CSS class name for the content
   * region of the component
   */
  contentClassName?: string
  /**
   * Optional on click handler for the avatar
   */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  /**
   * Optional CSS class name for notification badge
   */
  notificationClassName?: string
  /**
   * The count of notifications to be displayed in a badge
   * on the avatar
   */
  notificationValue?: number
  /**
   * Whether the notification badge should be visible
   */
  notificationVisible?: boolean
}

interface AvatarTextProps extends AvatarCommonProps {
  children: React.ReactNode
  /**
   * Avatar size. Defaults to 24
   */
  size?: AvatarTextSizes
}

interface AvatarImageProps extends AvatarCommonProps {
  /**
   * alt attribute for avatar image
   */
  alt: string
  /**
   * A fallback value to display if an avatar image is not found
   */
  fallbackValue: string
  /**
   * Avatar size. Defaults to 24
   */
  size?: AvatarImageSizes
  /**
   * src attribute for avatar image
   */
  src?: string
}

export type AvatarProps = AvatarImageProps | AvatarTextProps

const NotificationBadge = ({ children, className, size }) => {
  const rootClassName = cn(s.notificationRoot, className, {
    [s[`size${size}`]]: size,
  })

  return (
    <span className={rootClassName}>
      <Body color="white" size="miniscule" weight="semibold">
        {children}
      </Body>
    </span>
  )
}

/**
 * Component used to display an Avatar.
 * The avatar can be used to show a single letter or an image
 * at a small, medium, or large size.
 */
export const Avatar = (props: AvatarProps) => {
  const {
    className,
    contentClassName: providedContentClassName,
    onClick,
    notificationClassName,
    notificationValue,
    notificationVisible,
    size = 24,
  } = props

  const rootClassName = cn(s.avatarRoot, className, s[`size${size}`], {
    [s.notificationVisible]: notificationVisible,
    [s.avatarInteractive]: !!onClick,
  })

  const contentClassName = cn(s.avatarContent, providedContentClassName)

  const textClassName = cn(s.avatarText, [s[`textSize${size}`]])

  const TextAvatar = ({ children }) => {
    return (
      <div className={rootClassName} onClick={onClick}>
        <div className={contentClassName}>
          <Body className={textClassName}>{children}</Body>
          <Hidden visible={notificationVisible}>
            <NotificationBadge className={notificationClassName} size={size}>
              {notificationValue}
            </NotificationBadge>
          </Hidden>
        </div>
      </div>
    )
  }

  if ('children' in props) {
    const { children } = props
    return <TextAvatar>{children}</TextAvatar>
  }

  const { alt, fallbackValue, src } = props

  if (!src) return <TextAvatar>{fallbackValue}</TextAvatar>

  return (
    <div className={rootClassName} onClick={onClick}>
      <div className={contentClassName}>
        <img alt={alt} className={s.img} src={src} />
        <Hidden visible={notificationVisible}>
          <NotificationBadge className={notificationClassName} size={size}>
            {notificationValue}
          </NotificationBadge>
        </Hidden>
      </div>
    </div>
  )
}
