import { useRef, useEffect } from 'react'

import { requestAnimFrame } from 'utilities/animation'

const useAnimationFrame = callback => {
  const callbackRef = useRef(callback)
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const loop = () => {
    frameRef.current = requestAnimFrame(loop)
    const cb = callbackRef.current
    cb()
  }

  const frameRef = useRef()
  useEffect(() => {
    frameRef.current = requestAnimFrame(loop)
    return () => cancelAnimationFrame(frameRef.current)
  }, [])
}

export default useAnimationFrame
