import React from 'react'
import cn from 'classnames'
import Link from 'next/link'

import s from './styles.module.css'
import { withAnalyticsHandler } from 'hoc/with-analytics-handler'

type ButtonIconProps = {
  className?: string
  children?: React.ReactElement
  size?: ButtonSizes
}

export type ButtonSizes = 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large'

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'dark'
  | 'darkHollow'
  | 'textLight'
  | 'textDark'

export interface ButtonProps {
  style?: any
  /**
   * If set to true, button width will be set to auto
   */
  auto?: boolean
  /**
   * CSS class name to pass to the button container
   */
  className?: string
  children?: React.ReactNode
  /**
   * Whether the button should be in a disabled state
   */
  disabled?: boolean
  /**
   * Optional id to pass to root button
   */
  id?: string
  /**
   * Icon positioned to the left of the button text
   */
  iconLeft?: React.ReactElement
  /**
   * Icon position to the right of the button text
   */
  iconRight?: React.ReactElement
  /**
   * An optional property that adds additional interactions to the
   * button component (i.e. trailing icon shift right on hover)
   */
  interactive?: Boolean
  /**
   * Handler that fires when the button is clicked
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * Optional name attribute for the button
   */
  name?: string
  /**
   * Specifies the button size
   */
  size?: ButtonSizes
  /**
   * An optional property that allows the button to function as a link
   */
  to?: string
  type?: 'submit' | 'button' | 'reset' | undefined
  /**
   * The button styling variant
   */
  variant?: ButtonVariants
  /**
   * Whether the button text should be capitalized
   */
  capitalize?: boolean
  isExternal?: boolean
}

/**
 * Lookup object that maps button sizes to the
 * proper icon size based on the design system specs
 * Small and medium sized buttons use the small icon
 * size while the large sized button uses the medium
 * icon size
 */
export const ButtonIconSizeLookup = {
  xsmall: 'small',
  small: 'small',
  medium: 'small',
  large: 'medium',
}

/**
 * Wrapper component that gets used with the `iconLeft` and
 * `iconRight` props
 * We have some special logic in place here so that we can assign
 * the appropriate icon size to the provided icon based on the button
 * size
 * If we didn't do this, the developer would have to pass an icon
 * size prop with the provided icon every time which could easily
 * lead to inconsistencies throughout the app
 */
const ButtonIcon = ({
  className,
  children,
  size: sizeProp,
}: ButtonIconProps) => {
  const size = children?.props?.size || ButtonIconSizeLookup[sizeProp]
  const Icon = React.cloneElement(children, { size })
  return <span className={className}>{Icon}</span>
}

const ButtonComponent = ({
  style,
  auto = true,
  children,
  className,
  disabled,
  id,
  iconLeft,
  iconRight,
  interactive,
  name,
  onClick,
  size = 'medium',
  to,
  type = 'button',
  variant = 'primary',
  capitalize = true,
  isExternal,
}: ButtonProps) => {
  const mappedClassName = cn(s.root, className, {
    [s.auto]: auto,
    [s.linkAuto]: auto,
    [s.fullWidth]: !auto,
    [s[size]]: size,
    [s[variant]]: variant,
    [s.interactive]: interactive,
    [s.capitalize]: capitalize,
  })

  if (to) {
    const _isExternal =
      isExternal !== undefined ? isExternal : to?.startsWith('http')

    return (
      <Link
        href={to}
        style={style}
        className={mappedClassName}
        onClick={onClick}
        id={id || name}
        target={_isExternal ? '_blank' : undefined}
        rel={_isExternal ? 'noopener' : undefined}>
        {iconLeft && (
          <ButtonIcon className={s.iconLeft} size={size}>
            {iconLeft}
          </ButtonIcon>
        )}
        {children}
        {iconRight && (
          <ButtonIcon className={s.iconRight} size={size}>
            {iconRight}
          </ButtonIcon>
        )}
      </Link>
    )
  }

  return (
    <button
      style={style}
      className={mappedClassName}
      disabled={disabled}
      id={id}
      name={name}
      onClick={onClick}
      type={type}>
      {iconLeft && (
        <ButtonIcon className={s.iconLeft} size={size}>
          {iconLeft}
        </ButtonIcon>
      )}
      {children}
      {iconRight && (
        <ButtonIcon className={s.iconRight} size={size}>
          {iconRight}
        </ButtonIcon>
      )}
    </button>
  )
}

export const Button = withAnalyticsHandler(ButtonComponent, ['Click'])
