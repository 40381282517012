import React from 'react'
import cn from 'classnames'
import { BaseInput } from '../base-input'
import { Label } from '../label'
import { HelperText } from '../helper-text'
import { FormControlProps } from '../types'
import s from './styles.module.css'

export const TextInput = React.forwardRef<HTMLInputElement, FormControlProps>(
  function TextInput(props, ref) {
    const {
      className,
      disabled,
      error,
      errorText,
      excludeClearIcon,
      focus,
      helperText,
      hover,
      fullWidth = false,
      iconLeft,
      iconRight,
      inputProps,
      inputClassName,
      label,
      labelClassName,
      labelProps,
      helperTextClassName,
      helperTextProps,
      name,
      onBlur,
      onChange,
      onFocus,
      preventScrollOnFocus,
      required,
      type = 'text',
      value,
      ...rest
    } = props
    return (
      <div className={cn(className, s.textInputRoot)} {...rest}>
        <Label className={labelClassName} disabled={disabled} {...labelProps}>
          {!!label && <span className={s.labelText}>{label}</span>}
          <BaseInput
            className={inputClassName}
            disabled={disabled}
            error={error}
            excludeClearIcon={excludeClearIcon}
            hover={hover}
            focus={focus}
            fullWidth={fullWidth}
            iconLeft={iconLeft}
            iconRight={iconRight}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            preventScrollOnFocus={preventScrollOnFocus}
            ref={ref}
            required={required}
            type={type}
            value={value}
            {...inputProps}
          />
        </Label>
        <HelperText
          className={cn(s.helperText, helperTextClassName)}
          error={error}
          errorText={errorText}
          text={helperText}
          {...helperTextProps}
        />
      </div>
    )
  }
)
