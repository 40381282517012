import { createContext, useContext, useMemo, useRef } from 'react'
import cn from 'classnames'

import { useSafeAreaInsets } from 'hooks/use-safe-area-insets'
import s from './styles.module.css'

export type DrawerVariants = 'permanent' | 'rail' | 'temporary'

interface DrawerContextProps {
  darkMode?: boolean
  open?: boolean
  variant?: DrawerVariants
}

interface DrawerProps {
  anchor?: 'left' | 'right'
  children: React.ReactNode
  className?: string
  darkMode?: boolean
  open?: boolean
  onOpen?: (event: React.MouseEvent | React.KeyboardEvent) => void
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  variant?: DrawerVariants
}

export const DrawerContext = createContext<DrawerContextProps>(null)

export const useDrawer = () => {
  const context = useContext(DrawerContext)

  return {
    darkMode: context?.darkMode,
    open: context?.open,
    variant: context?.variant,
  }
}

export const Drawer = ({
  anchor = 'left',
  children,
  className,
  darkMode = false,
  open,
  onClose,
  onOpen,
  variant,
}: DrawerProps) => {
  const contextValue = useMemo(
    () => ({ darkMode, open, variant }),
    [darkMode, open, variant]
  )

  /**
   * Setup the click away utility that ensures the onClose
   * event fires when the user clicks outside the drawer area
   * or hits esc
   */
  const drawerRef = useRef(null)

  const handleMouseEnter = (event: React.MouseEvent) => {
    if (variant === 'rail' && !!onOpen) onOpen(event)
  }

  const handleMouseLeave = (event: React.MouseEvent) => {
    if (variant === 'rail' && !!onClose) onClose(event)
  }

  const insets = useSafeAreaInsets()

  return (
    <DrawerContext.Provider value={contextValue}>
      <div
        className={cn(
          s.drawerRoot,
          s[anchor],
          s[variant],
          { [s.open]: open },
          className
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={drawerRef}
        style={{
          paddingTop: Math.max(insets.top - 26, 0),
          paddingBottom: Math.max(insets.bottom - 24, 0),
        }}>
        {children}
      </div>
      <div className={cn(s.overlay, { [s.open]: open })} onClick={onClose} />
    </DrawerContext.Provider>
  )
}
