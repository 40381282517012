import React from 'react'
import cn from 'classnames'

import { RadioOption } from '../radio-option'
import { Hidden } from 'components/design-system/hidden'
import s from './styles.module.css'

type RadioGroupProps = {
  /**
   * CSS class name to provide to the root radio group
   */
  className?: string
  /**
   * Whether the form control is disabled
   */
  disabled?: boolean
  /**
   * Label to display above the radio group
   */
  label?: React.ReactNode
  /**
   * CSS class name to provide to the radio group label
   */
  labelClassName?: string
  /**
   * Name of the form control. Submitted with the form as part of a name/value pair.
   */
  name?: string
  /**
   * CSS class name to provide to the root container for an individual
   * radio group option
   */
  optionClassName?: string
  /**
   * CSS class name to provide to the root container for an individual
   * radio group option that is checked
   */
  optionCheckedClassName?: string
  /**
   * CSS class name to provide to the icon for an individual
   * radio group option
   */
  optionIconClassName?: string
  /**
   * CSS class name to provide to the label container for an individual
   * radio group option
   */
  optionLabelClassName?: string
  /**
   * CSS class name to provide to the subtext container for an individual
   * radio group option
   */
  optionSubTextClassName?: string
  /**
   * Array of objects representing the options that
   * should be rendered as part of the checkbox group
   */
  options: {
    checked?: boolean
    disabled?: boolean
    focus?: boolean
    hover?: boolean
    label: string
    subText?: string
    value: string | number | boolean
  }[]
  /**
   * Event handler for the blur event
   */
  onBlur?: (value: string | number) => void
  /**
   * Event handler for the input change event
   */
  onChange: (value: string | number) => void
  /**
   * Event handler for the focus event
   */
  onFocus?: (value: string | number) => void
  /**
   * Event handler for the key down event
   */
  onKeyDown?: (value: string | number) => void
  /**
   * Array of values representing the checked options
   * for the form control
   */
  value: string | number | boolean
}

export const RadioGroup = ({
  className,
  disabled,
  label,
  labelClassName,
  name,
  optionClassName,
  optionCheckedClassName,
  optionIconClassName,
  optionLabelClassName,
  optionSubTextClassName,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  options,
  value,
}: RadioGroupProps) => {
  return (
    <fieldset className={cn(s.radioGroupRoot, className)} name={name}>
      <Hidden visible={!!label}>
        <legend className={cn(s.radioGroupLegend, labelClassName)}>
          {label}
        </legend>
      </Hidden>
      {options.map(option => {
        const isChecked = value === option.value

        return (
          <RadioOption
            key={option.value}
            checked={isChecked}
            className={cn(optionClassName, {
              [optionCheckedClassName]: isChecked,
            })}
            disabled={disabled || option.disabled}
            hover={option.hover}
            focus={option.focus}
            label={option.label}
            subText={option.subText}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            iconClassName={optionIconClassName}
            labelClassName={optionLabelClassName}
            subTextClassName={optionSubTextClassName}
            value={option.value}
            id={option.value}
          />
        )
      })}
    </fieldset>
  )
}
