import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  ReactNode,
} from 'react'
import * as R from 'ramda'
import cn from 'classnames'

import { Hidden } from 'components/design-system'
import { ClearIcon } from 'components/design-system/kickoff-icons'
import s from './styles.module.css'

export interface BaseTextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Whether the form control is in an error state
   */
  error?: boolean
  /**
   * Escape hatch for controlling whether the input should be
   * shown in a focus state
   */
  focus?: boolean
  /**
   * Escape hatch for controlling whether the input should be
   * shown in a hover state
   */
  hover?: boolean
  /**
   * Whether the input should fully grow into the
   * available space
   */
  fullWidth?: boolean
  /**
   * Icon to be rendered on the left-side of the input
   */
  iconLeft?: React.ReactElement
  /**
   * Icon to be rendered on the right-side of the input
   */
  iconRight?: React.ReactElement
  /**
   * Event handler for the input blur event
   */
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  /**
   * Event handler for the input change event
   */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  /**
   * Event handler for the input focus event
   */
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  /**
   * Event handler that fires anytime the input is validated
   */
  onValidate?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  /**
   * Whether the default scroll to element on focus behavior
   * should be enabled
   */
  preventScrollOnFocus?: boolean
  /**
   * Boolean indicating whether the clear "x" icon should be shown
   */
  excludeClearIcon?: boolean
  /**
   * Optional render function to wrap textarea node
   */
  renderTextAreaContainer?: (children: ReactNode) => ReactNode
}

type TextAreaIconProps = {
  children?: React.ReactElement
  className?: string
  onClick?: (event: React.MouseEvent) => void
  size?: 'small' | 'medium' | 'large'
}

/**
 * Wrapper component that gets used with the `iconLeft` and
 * `iconRight` props
 * We have some special logic in place here so that we can assign
 * the appropriate icon size to the provided icon
 * If we didn't do this, the developer would have to pass an icon
 * size prop with the provided icon every time which could easily
 * lead to inconsistencies throughout the app
 */
const TextAreaIcon = ({
  className,
  children,
  onClick,
  size: sizeProp,
}: TextAreaIconProps) => {
  const size = children?.props?.size || sizeProp
  const Icon = React.cloneElement(children, { size })
  return (
    <span className={className} onClick={onClick}>
      {Icon}
    </span>
  )
}

/**
 * The BaseInput component is meant to serve as a bare bones
 * but highly configurable input component for all open field
 * input types (i.e. think email, number, url, tel, text, etc)
 */
export const BaseTextarea = React.forwardRef<
  HTMLTextAreaElement,
  BaseTextareaProps
>(function BaseTextarea(props, ref) {
  const {
    autoFocus,
    className,
    disabled,
    error,
    focus,
    hover,
    fullWidth,
    iconLeft,
    iconRight,
    name,
    onBlur = () => {},
    onChange,
    onFocus,
    onValidate,
    preventScrollOnFocus = false,
    value,
    excludeClearIcon = false,
    renderTextAreaContainer = children => children,
    ...rest
  } = props

  const baseRef = useRef<HTMLTextAreaElement | null>(null)
  const textareaRef = ref ? ref : baseRef
  const [focused, setFocused] = useState(autoFocus || focus)
  const isControlled = !R.isNil(value) && !!onChange

  const setHeight = useCallback(height => {
    if (!baseRef.current) return

    baseRef.current.style.height = height
  }, [])

  const resetHeight = useCallback(() => setHeight('auto'), [setHeight])

  /**
   * Utility for auto-resizing the textarea
   * based on the user's input
   */
  const resizeTextarea = useCallback(() => {
    if (!baseRef.current) return

    setHeight(`${baseRef.current.scrollHeight}px`)
  }, [setHeight])

  /**
   * Logic used to auto-resize the textarea height based
   * on the textarea value
   */
  useEffect(() => {
    value ? resizeTextarea() : resetHeight()
  }, [resetHeight, resizeTextarea, value])

  /**
   * Currently necessary to include this use effect
   * to make the handleClear functionality work
   */
  useEffect(() => {
    setFocused(focus)
  }, [focus])

  /**
   * Also kind of a hacky workaround to ensure that
   * autoFocus takes precendence on render
   */
  useEffect(() => {
    setFocused(autoFocus)
  }, [autoFocus])

  /**
   * This useEffect hook is necessary to properly focus
   * and blur the input
   * Because the element that is actually being styled to look
   * like an input is not actually an input (because we need to support
   * left and right icons), we need to programmatically kick off
   * focus and blur events whenever the `focused` state variable
   * changes
   * An alternative approach could be to add a custom click away listener
   * that listens for clicks outside of the parent div in this component
   */
  useEffect(() => {
    if (baseRef.current) {
      if (focused) {
        baseRef.current.focus({ preventScroll: preventScrollOnFocus })
      } else {
        baseRef.current.blur()
      }
    }
  }, [baseRef, focused, preventScrollOnFocus])

  /**
   * Define supplement state based class names for the input
   */
  const textareaStateClassNames = {
    [s.disabled]: disabled,
    [s.error]: error,
    [s.hover]: hover,
    [s.focus]: focused || focus,
    [s.fullWidth]: fullWidth,
  }

  const textareaClassName = cn(
    s.textareaRoot,
    className,
    textareaStateClassNames
  )
  const textareaInnerClassName = cn(s.textareaInner, textareaStateClassNames)

  const handleChange = e => {
    onChange && onChange(e)
    setTimeout(resizeTextarea)
  }

  /**
   * Event handler used to clear the input when
   * the user clicks on the clear icon that is exposed
   * in the focus state
   */
  const handleClear = e => {
    if (!isControlled && baseRef.current) {
      baseRef.current.value = ''
    }
    resetHeight()
    handleChange({
      target: {
        value: '',
      },
    })
    setFocused(true)
  }

  const handleFocus = e => {
    onFocus && onFocus(e)
    setFocused(true)
  }

  /**
   * Event handler for the blur event
   * Currently utilizing a somewhat janky implementation where
   * we set focused to false and delay the blur event from firing
   * so that the handleClear event can be fired off before the blur event
   * @param {*} e
   */
  const handleBlur = e => {
    // TODO find workaround
    setTimeout(() => {
      onBlur && onBlur(e)
      setFocused(false)
      if (e.target.value === '') {
        resetHeight()
      }
    }, 150)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event?.key === 'Backspace' || event?.key === 'Delete') {
      resetHeight()
    }
  }

  return (
    <div className={textareaClassName} onClick={handleFocus}>
      {iconLeft && (
        <TextAreaIcon className={s.iconLeft} size="medium">
          {iconLeft}
        </TextAreaIcon>
      )}
      {renderTextAreaContainer(
        <textarea
          autoFocus={autoFocus}
          className={textareaInnerClassName}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          name={name}
          ref={e => {
            typeof textareaRef === 'function' && textareaRef(e)
            typeof textareaRef === 'object' && (textareaRef.current = e)
            baseRef.current = e
          }}
          value={value}
          {...rest}
        />
      )}

      <Hidden visible={!excludeClearIcon}>
        <TextAreaIcon
          className={focused || focus ? s.iconClear : s.iconClearHidden}
          onClick={focused || focus ? handleClear : undefined}>
          <ClearIcon />
        </TextAreaIcon>
      </Hidden>

      {iconRight && (
        <TextAreaIcon className={s.iconRight} size="medium">
          {iconRight}
        </TextAreaIcon>
      )}
    </div>
  )
})

BaseTextarea.defaultProps = {
  fullWidth: false,
  preventScrollOnFocus: false,
}
