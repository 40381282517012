import { useMemo, FunctionComponent } from 'react'
import * as R from 'ramda'

import { useAnalytics } from 'hooks/use-analytics'

interface AnalyticsProps {
  name?: string
  [key: string]: any
}

export const withAnalyticsHandler = (
  Component: FunctionComponent | string,
  actions: string[]
) => {
  return (baseProps: AnalyticsProps) => {
    const logEvent = useAnalytics(baseProps.name)

    const handlerProps = useMemo(() => {
      if (!baseProps.name) return {}

      return R.fromPairs(
        R.map(action => {
          const eventProp = `on${action}`
          const propsHandler = baseProps[eventProp]

          return [
            eventProp,
            (...args) => {
              logEvent(action)
              if (propsHandler) propsHandler(...args)
            },
          ]
        }, actions)
      )
    }, [baseProps, logEvent])

    const props = useMemo(() => {
      return R.mergeRight(baseProps, handlerProps)
    }, [baseProps, handlerProps])

    return <Component {...props} />
  }
}
