import React from 'react'
import Link from 'next/link'

type BaseButtonProps = {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent) => void
  to?: string
  type?: 'button' | 'submit' | 'reset' | undefined
}

/**
 * A boring, un-opinionated base button component
 * that can be used as an escape hatch when the design system
 * Button component cannot be used
 */
export const BaseButton = ({
  children,
  className,
  disabled,
  onClick,
  to,
  type = 'button',
}: BaseButtonProps) => {
  if (to) {
    const isExternal = to?.startsWith('http')
    return (
      <Link
        href={to}
        className={className}
        onClick={onClick}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'noopener' : undefined}>
        {children}
      </Link>
    )
  }

  return (
    <button
      className={className}
      disabled={disabled}
      type={type}
      onClick={onClick}>
      {children}
    </button>
  )
}
