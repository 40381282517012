import React from 'react'
import cn from 'classnames'
import { BadgeText, BadgeSize } from 'components/design-system/type-system'
import s from './styles.module.css'
import { Hidden } from '../hidden'

interface BadgeProps {
  children: React.ReactNode
  className?: string
  iconLeft?: React.ReactElement
  iconRight?: React.ReactElement
  numberOnly?: boolean
  size?: 10 | 12 | 14
  textClassName?: string
  uppercase?: boolean
  variant?: 'filled' | 'outlined'
}

const BADGE_SIZE_TO_TYPOGRAPHY_SIZE_LOOKUP = {
  10: 'xsmall',
  12: 'small',
  14: 'medium',
}

/**
 * Wrapper component that gets used with the `iconLeft` and
 * `iconRight` props
 * We have some special logic in place here to assign the size
 * medium to the provided icon
 * This helps ensure consistency and removes that burden from the dev
 */
const BadgeIcon = ({
  children,
  className,
}: {
  children: React.ReactElement
  className?: string
}) => {
  const Icon = React.cloneElement(children, { size: 'small' })
  return <span className={className}>{Icon}</span>
}

export const Badge = ({
  className,
  children,
  iconLeft,
  iconRight,
  numberOnly = false,
  size = 12,
  textClassName,
  variant = 'filled',
  uppercase = false,
}: BadgeProps) => {
  return (
    <span
      className={cn(
        s.badgeRoot,
        s[`size${size}`],
        s[variant],
        { [s.numberOnly]: numberOnly },
        className
      )}>
      <Hidden visible={!!iconLeft}>
        <BadgeIcon className={s.iconLeft}>{iconLeft}</BadgeIcon>
      </Hidden>
      <BadgeText
        className={cn(s.badgeText, textClassName)}
        size={BADGE_SIZE_TO_TYPOGRAPHY_SIZE_LOOKUP[size] as BadgeSize}
        uppercase={uppercase}>
        {children}
      </BadgeText>
      <Hidden visible={!!iconRight}>
        <BadgeIcon className={s.iconRight}>{iconRight}</BadgeIcon>
      </Hidden>
    </span>
  )
}
