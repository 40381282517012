import * as R from 'ramda'

const spacingRuleLookup = {
  m: ['margin'],
  mb: ['marginBottom'],
  ml: ['marginLeft'],
  mr: ['marginRight'],
  mt: ['marginTop'],
  mx: ['marginLeft', 'marginRight'],
  my: ['marginTop', 'marginBottom'],
  p: ['padding'],
  pb: ['paddingBottom'],
  pl: ['paddingLeft'],
  pr: ['paddingRight'],
  pt: ['paddingTop'],
  px: ['paddingLeft', 'paddingRight'],
  py: ['paddingTop', 'paddingBottom'],
}

const spacingKeys = Object.keys(spacingRuleLookup)

export const generateCSS = props => {
  const cssRules = Object.entries(props).reduce(
    (css, [ruleName, ruleValue]) => {
      if (!R.isNil(ruleValue)) {
        if (ruleName === 'gap') {
          if (props?.flexDirection === 'column') {
            css['> *:not(:last-child)'] = {
              marginBottom: `${ruleValue}rem`,
            }
          } else {
            css['> *:not(:last-child)'] = {
              marginRight: `${ruleValue}rem`,
            }
          }
        } else {
          if (spacingKeys.includes(ruleName)) {
            spacingRuleLookup[ruleName].forEach(spacingRule => {
              css[spacingRule] = `${ruleValue}rem`
            })
          } else {
            css[ruleName] = ruleValue
          }
        }
      }
      return css
    },
    {}
  )
  return cssRules
}
