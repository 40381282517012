import React from 'react'
import cn from 'classnames'

import { Hidden } from 'components/design-system/hidden'
import { InfoIcon as Info } from 'components/design-system/kickoff-icons'
import s from './styles.module.css'

export type HelperTextProps = {
  /**
   * Class name to pass to the root of the component
   */
  className?: string
  /**
   * Whether the form control is in an error state
   */
  error?: boolean
  /**
   * Text that should be displayed when the form control
   * is in an error state
   */
  errorText?: React.ReactNode
  /**
   * Boolean indicating whether the info/error icon should be
   * shown alongside the helper/error text
   */
  excludeIcon?: boolean
  /**
   * Helper text that should can provide additional hints for
   * the form control
   */
  text?: React.ReactNode
  /**
   * Custom icon to show in place of InfoIcon
   */
  icon?: React.ReactElement
}

const InfoIcon = ({
  visible,
  icon,
}: {
  visible: boolean
  icon?: React.ReactElement
}) => {
  const iconNode = icon ? (
    React.cloneElement(icon, {
      className: icon?.props?.className || s.icon,
      size: icon?.props?.size || 'small',
    })
  ) : (
    <Info className={s.icon} size="small" />
  )

  return <Hidden visible={visible}>{iconNode}</Hidden>
}

/**
 * Component used to display helper text (aka a hint) for a
 * form control
 */
export const HelperText = ({
  className,
  error,
  errorText,
  excludeIcon = false,
  text,
  icon,
}: HelperTextProps) => {
  const helperTextClassName = cn(s.helperTextRoot, className, {
    [s.error]: error,
  })
  const showIcon = (!!text || (!!error && !!errorText)) && !excludeIcon

  if (!text && !errorText) return null
  return (
    <div className={helperTextClassName}>
      {error ? (
        <div className={s.textWrapper}>
          <InfoIcon visible={showIcon} icon={icon} />
          <span>{errorText}</span>
        </div>
      ) : (
        <div className={s.textWrapper}>
          <InfoIcon visible={showIcon} icon={icon} />
          <span>{text}</span>
        </div>
      )}
    </div>
  )
}
