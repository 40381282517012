import cn from 'classnames'
import { Paper } from 'components/design-system'
import { useMenu } from 'components/design-system/menu'
import s from './styles.module.css'

interface MenuListProps {
  children: React.ReactNode
  /**
   * Optional CSS class name to pass to the root of the component
   */
  className?: string
  /**
   * Whether the menu list is anchored to left or right of the parent
   * menu container
   */
  horizontalPosition?: 'left' | 'right'
  /**
   * Whether the menu list is anchored above or below the parent
   * menu container
   */
  verticalPosition?: 'bottom' | 'top'
}

export const MenuList = ({ children, className }: MenuListProps) => {
  const { refs, x, y, strategy, open, getFloatingProps } = useMenu('MenuList')

  if (!open) return null

  return (
    <Paper
      className={cn(s.menuListRoot, className)}
      ref={refs.setFloating}
      style={{
        position: strategy,
        top: y,
        left: x,
      }}
      {...getFloatingProps()}
      disablePadding
      elevation="sm">
      <ul className={s.menuListContent}>{children}</ul>
    </Paper>
  )
}
