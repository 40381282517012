import React from 'react'
import cn from 'classnames'

import colors from 'styles/colors.module.css'
import { TextInput } from 'components/design-system'
import { SearchIcon } from 'components/design-system/kickoff-icons'
import { FormControlProps } from '../types'
import s from './styles.module.css'

export const SearchInput = React.forwardRef<HTMLInputElement, FormControlProps>(
  function (props, ref) {
    return (
      <TextInput
        ref={ref}
        {...props}
        type="text"
        className={cn(s.search, s.searchInputWrapper)}
        inputProps={{
          ...(props.inputProps || {}),
          className: s.searchInput,
          focusClassName: s.searchInputFocused,
          hoverClassName: s.searchInputHovered,
          iconRightClassName: s.searchInputIconWrapper,
        }}
        iconRight={
          <SearchIcon
            color={colors.NEUTRAL_GRAY_600}
            size="medium"
            className={s.searchInputIcon}
          />
        }
      />
    )
  }
)
