import React from 'react'
import cn from 'classnames'
import { Checkbox, CheckboxProps } from '../checkbox'
import { HelperText } from '../helper-text'
import s from './styles.module.css'

type CheckboxGroupValue = (string | number)[]

type CheckboxGroupProps = {
  /**
   * Object representing props that should be passed down
   * to the `<Checkbox />` component. Review the docs for that
   * component to see the available options
   */
  checkboxProps?: CheckboxProps
  /**
   * Optional CSS class name to pass to the component root
   */
  className?: string
  /**
   * Optional CSS class name to pass to each option
   */
  optionClassName?: string
  /**
   * Whether the form control is disabled
   */
  disabled?: boolean
  /**
   * Optional text to provide helpful hints on the checkbox
   * group control
   */
  helperText?: string
  /**
   * Text to display above the form control
   */
  label?: string
  /**
   * Name of the form control. Submitted with the form as part of a name/value pair.
   */
  name?: string
  /**
   * Array of objects representing the options that
   * should be rendered as part of the checkbox group
   */
  options?: {
    checked?: boolean
    disabled?: boolean
    label: string
    value: string | number
  }[]
  /**
   * Event handler for the input change event
   */
  onChange: (CheckboxGroupValue) => void
  /**
   * Event handler for the key down event
   */
  onKeyDown?: (CheckboxGroupValue) => void
  /**
   * Array of values representing the checked options
   * for the form control
   */
  value: CheckboxGroupValue
}

type GetValueArgs = {
  checked: boolean
  existingValue: CheckboxGroupValue
  newValue: string | number
}

/**
 * Utility for returning an updated checkbox group value
 * @param {object} options
 * @param {boolean} options.checked boolean indicating whether the user
 * just checked or unchecked a group item
 * @param {array} options.existingValue array of existing checkbox
 * group values
 * @param {string | number} options.newValue value associated with the group
 * item the user just checked/unchecked
 * @returns {array} returns an updated array of currently checked checkbox group items
 */
const getValue = ({
  checked,
  existingValue,
  newValue,
}: GetValueArgs): CheckboxGroupValue => {
  const updatedValue = [...existingValue]
  if (!checked) {
    const existingIndex = updatedValue.indexOf(newValue)
    updatedValue.splice(existingIndex, 1)
  } else {
    updatedValue.push(newValue)
  }
  return updatedValue
}

/**
 * Component used to render a checkbox group control
 */
export const CheckboxGroup = (props: CheckboxGroupProps) => {
  const {
    checkboxProps,
    className,
    optionClassName,
    disabled,
    helperText,
    label,
    name,
    options,
    onChange,
    onKeyDown,
    value = [],
  } = props

  /**
   * Event handler for the change event
   * This component is intended to be used as multi-select
   * and as a result we need to properly add or remove
   * items from the value array based on the user's action
   * @param {object} event
   * @param {string | number} val value associated with the selected
   * checkbox group item
   */
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    val: string | number
  ) => {
    const { target } = event
    const updatedValue = getValue({
      checked: target?.checked,
      existingValue: value,
      newValue: val,
    })
    onChange(updatedValue)
  }

  /**
   * Event handler for the key down event
   * This component is intended to be used as multi-select
   * and as a result we need to properly add or remove
   * items from the value array based on the user's action
   * @param {object} event
   * @param {string | number} val value associated with the selected
   * checkbox group item
   */
  const handleKeyDown = (event, val) => {
    const { target } = event
    const updatedValue = getValue({
      checked: target?.checked,
      existingValue: value,
      newValue: val,
    })
    onChange(updatedValue)
    if (onKeyDown) {
      onKeyDown(updatedValue)
    }
  }

  return (
    <fieldset className={cn(className, s.checkboxGroupRoot)} name={name}>
      <legend className={s.checkboxGroupLegend}>{label}</legend>
      <HelperText className={s.helperText} text={helperText} />
      {options.map(option => (
        <Checkbox
          key={option.value}
          className={optionClassName}
          checked={value?.includes(option.value)}
          disabled={disabled || option.disabled}
          label={option.label}
          onChange={event => handleChange(event, option.value)}
          onKeyDown={event => handleKeyDown(event, option.value)}
          {...checkboxProps}
        />
      ))}
    </fieldset>
  )
}
