import cn from 'classnames'
import s from './styles.module.css'

export interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  /**
   * Whether or not the label should be displayed in
   * a disabled state
   */
  disabled?: boolean
}

/**
 * This component is used to display a form control label
 */
export const Label = ({
  className,
  children,
  disabled,
  ...rest
}: LabelProps) => {
  const labelClassName = cn(s.labelRoot, className, { [s.disabled]: disabled })
  return (
    <label className={labelClassName} {...rest}>
      {children}
    </label>
  )
}
