import { useCallback } from 'react'
import * as R from 'ramda'

import AnalyticsManager from 'utilities/analytics-manager'
import { useAnalyticsPage } from 'containers/analytics/page'
import { useAnalyticsSection } from 'containers/analytics/section'
import { EventProperties } from 'types/analytics'

const IS_PROD = process.env.APP_ENV === 'production'

export const useAnalytics = (
  elementName?: string,
  elementProperties?: EventProperties
) => {
  const page = useAnalyticsPage()
  const section = useAnalyticsSection()

  const logEvent = useCallback(
    (
      action: string,
      properties?: EventProperties,
      allowMissingSection: boolean = false
    ) => {
      const values = {
        pageName: page?.name,
        sectionName: section?.name,
        elementName,
        action,
      }

      if (!page) {
        if (!IS_PROD) console.warn(`useAnalytics missing page name`, values)

        return
      }

      if (!section && !allowMissingSection) {
        if (!IS_PROD) console.warn(`useAnalytics missing section name`, values)

        return
      }

      AnalyticsManager.logEvent(
        R.join(
          ' - ',
          R.reject(R.isNil, [page.name, section?.name, elementName, action])
        ),
        addLegacyProperties(
          R.mergeAll(
            R.reject(R.isNil, [
              page.properties,
              section?.properties,
              elementProperties,
              properties,
            ])
          )
        )
      )
    },
    [elementName, elementProperties, page, section]
  )

  return logEvent
}

const LEGACY_PROPERTY_MAP = {
  coachId: 'Coach ID',
  coachProfileVariant: 'Coach Profile Variant',
  signUpVariant: 'Sign Up Variant',
}

const addLegacyProperties = (properties: EventProperties): {} => {
  const legacyProperties = {}

  for (const key of R.keys(LEGACY_PROPERTY_MAP)) {
    const legacyKey = LEGACY_PROPERTY_MAP[key]

    if (R.has(key, properties) && !R.has(legacyKey, properties)) {
      legacyProperties[legacyKey] = properties[key]
    }
  }

  return R.mergeRight(legacyProperties, properties)
}
