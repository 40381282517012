import cx from 'classnames'

import s from './styles.module.css'
import React from 'react'

export type PaperElevations = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type PaperProps = {
  className?: string
  children?: React.ReactNode
  disablePadding?: boolean
  /**
   * Level of elevation that should be applied to the card
   */
  elevation?: PaperElevations
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  role?: string
  style?: React.CSSProperties
}

/**
 * The Paper component is a surface component that functions
 * similarly to the Card component.
 * While the Card component should be used to display
 * information and actions about a single subject,
 * the Paper component is a more flexible general
 * container component that can be used to organize and
 * group related content
 */
export const Paper = React.forwardRef<HTMLDivElement, PaperProps>(
  function Paper(props, ref) {
    const {
      className,
      children,
      disablePadding = false,
      elevation = 'xs',
      onClick,
      role,
      style,
    } = props
    const elevationClass =
      elevation === 'none' ? '' : s[`elevation-${elevation}`]
    return (
      <div
        className={cx(s.root, elevationClass, className, {
          [s.disablePadding]: disablePadding,
        })}
        onClick={onClick}
        ref={ref}
        role={role}
        style={style}>
        {children}
      </div>
    )
  }
)
