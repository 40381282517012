import { createContext, ReactNode, useContext, useMemo, useEffect } from 'react'

import { EventProperties } from 'types/analytics'
import { useAnalytics } from 'hooks/use-analytics'

interface AnalyticsPageInterface {
  name: string
  properties: EventProperties
}

const AnalyticsPageContext = createContext<AnalyticsPageInterface | null>(null)

export const useAnalyticsPage = () => useContext(AnalyticsPageContext)

export interface AnalyticsPageProps {
  name: string
  properties?: EventProperties
  children: ReactNode
  trackView?: boolean
}

const AnalyticsPageContent = ({ children, trackView }) => {
  const logEvent = useAnalytics()

  useEffect(() => {
    if (trackView) logEvent('View', {}, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export const AnalyticsPage = ({
  children,
  name,
  properties = {},
  trackView = true,
}: AnalyticsPageProps) => {
  const contextValue = useMemo(() => ({ name, properties }), [name, properties])

  return (
    <AnalyticsPageContext.Provider value={contextValue}>
      <AnalyticsPageContent trackView={trackView}>
        {children}
      </AnalyticsPageContent>
    </AnalyticsPageContext.Provider>
  )
}
