import cn from 'classnames'
import { Button } from '../button'
import { ChevronDownIcon, ChevronUpIcon } from '../kickoff-icons'

import s from './styles.module.css'
import { useMenu } from 'components/design-system/menu'

interface MenuButtonProps {
  children: React.ReactNode
  /**
   * Optional CSS class name to pass to the root
   * of the component
   */
  className?: string
  customIcon?: React.ReactNode
}

/**
 * Component used to render a stylized button that
 * controls opening and closing a menu
 */
export const MenuButton = ({
  children,
  className,
  customIcon,
}: MenuButtonProps) => {
  const { open } = useMenu('MenuButton')

  const icon = open ? (
    <ChevronUpIcon size="medium" />
  ) : (
    <ChevronDownIcon size="medium" />
  )

  return (
    <Button
      className={cn(s.menuButtonRoot, className)}
      iconRight={customIcon || icon}
      variant="textDark">
      {children}
    </Button>
  )
}
