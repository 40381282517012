import cn from 'classnames'
import { Divider } from 'components/design-system'
import { useDrawer } from 'components/design-system/drawer'

import s from './styles.module.css'

export const DrawerDivider = ({ className }: { className?: string }) => {
  const { darkMode } = useDrawer()
  return (
    <Divider
      className={cn(s.drawerDividerRoot, className)}
      darkMode={darkMode}
    />
  )
}
