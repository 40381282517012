import React from 'react'
import cn from 'classnames'
import { Badge, Flex, Hidden, MenuItem } from 'components/design-system'
import { useDrawer } from 'components/design-system/drawer'

import s from './styles.module.css'

interface DrawerItemProps {
  iconLeft?: React.ReactElement
  label: React.ReactNode
  onClick?: (event: React.MouseEvent) => void
  notificationValue?: number | string
  selected?: boolean
  notificationVisible?: boolean
  to?: string
}

export const DrawerItem = React.forwardRef<HTMLLIElement, DrawerItemProps>(
  function DrawerItem(props, ref) {
    const {
      iconLeft,
      label,
      onClick,
      notificationValue,
      notificationVisible,
      selected,
      to,
    } = props
    const { darkMode, open, variant } = useDrawer()
    const isRailVariant = variant === 'rail'
    const showFullNotification = !isRailVariant || open

    return (
      <MenuItem
        className={cn(s.drawerItemRoot, {
          [s.rail]: isRailVariant,
          [s.open]: open,
        })}
        darkMode={darkMode}
        iconClassName={s.drawerItemIcon}
        iconLeft={iconLeft}
        onClick={onClick}
        selected={selected}
        ref={ref}
        to={to}>
        <Flex alignItems="center" justifyContent="space-between">
          <span className={s.drawerItemText}>{label}</span>
          <Hidden visible={notificationVisible}>
            <Hidden visible={!showFullNotification}>
              <span className={s.notificationIndicator} />
            </Hidden>
            <Hidden visible={showFullNotification && !!notificationValue}>
              <Badge>{notificationValue}</Badge>
            </Hidden>
          </Hidden>
        </Flex>
      </MenuItem>
    )
  }
)
