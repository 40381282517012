import cn from 'classnames'
import Link from 'next/link'
import Image from 'next/legacy/image'

import { Flex, Hidden } from 'components/design-system'
import { XIcon } from 'components/design-system/kickoff-icons'
import { useDrawer } from 'components/design-system/drawer'

import KickoffLetterLogo from 'images/kickoff-logo-letter.svg'
import KickoffWordOnlyLogo from 'images/kickoff-word-only-white.svg'
import s from './styles.module.css'
import urls from 'utilities/urls'

export const DrawerLogo = ({
  onClose,
  showCloseButton = false,
}: {
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  showCloseButton?: boolean
}) => {
  const { open, variant } = useDrawer()

  const handleKeyboardClose = event => {
    if (event?.code === 'Enter') {
      onClose(event)
    }
  }

  return (
    <Flex
      alignItems="center"
      className={s.drawerLogoRoot}
      justifyContent="space-between">
      <Link
        href={urls.todosDashboard({ clientId: null })}
        className={s.drawerLogoContent}
        onClick={onClose}>
        <Image
          alt="Kickoff Logo"
          src={KickoffLetterLogo}
          height={24}
          width={16}
        />
        <div
          className={cn(s.drawerLogoText, {
            [s.open]: open || variant === 'permanent',
          })}>
          <Image
            alt="Kickoff Logo"
            src={KickoffWordOnlyLogo}
            height={20}
            width={85}
          />
        </div>
      </Link>
      <Hidden visible={showCloseButton}>
        <div
          className={s.drawerCloseIconContainer}
          tabIndex={0}
          onClick={onClose}
          onKeyDown={handleKeyboardClose}>
          <XIcon className={s.drawerCloseIcon} size="small" />
        </div>
      </Hidden>
    </Flex>
  )
}
