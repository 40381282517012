import cn from 'classnames'

import { BaseTypography, BaseTypographyProps } from '../base-typography'

import s from './styles.module.css'

interface DisplayProps extends BaseTypographyProps {
  /**
   * Specify the size variant of the Display component
   */
  size?: 'small' | 'large' | 'largeNumber'
  /**
   * Optional prop that locks you into either the mobile or desktop
   * type system regardless of the screen size and breakpoint styles
   * The default behavior is to use the mobile type system variant of a size on mobile
   * and then use the desktop type system variant on table and above. This prop
   * provides an escape hatch for that behavior
   */
  typeSystem?: 'mobile' | 'desktop'
}

export const Display = ({
  className,
  children,
  size = 'large',
  tag = 'h1',
  typeSystem,
  weight = 'semibold',
  ...rest
}: DisplayProps) => {
  return (
    <BaseTypography
      className={cn(s[size], s[typeSystem], className)}
      tag={tag}
      weight={weight}
      {...rest}>
      {children}
    </BaseTypography>
  )
}
