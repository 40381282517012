import React, { HTMLAttributes } from 'react'
import cn from 'classnames'
import { BaseTextarea, BaseTextareaProps } from '../base-textarea'
import { Label, LabelProps } from '../label'
import { HelperText, HelperTextProps } from '../helper-text'
import { Body } from 'components/design-system/type-system'
import s from './styles.module.css'
import { Edit2Icon } from 'components/design-system/kickoff-icons'

export type TextAreaModes = 'view' | 'edit'

interface CommonTextareaProps extends BaseTextareaProps {
  /**
   * Text that should be displayed when the form control
   * is in an error state
   */
  errorText?: React.ReactNode
  /**
   * Helper text that should can provide additional hints for
   * the form control
   */
  helperText?: React.ReactNode

  /**
   * CSS class name to apply to the `<BaseInput />` component
   */
  inputClassName?: string
  /**
   * Object representing props that should be passed down
   * to the `<BaseInput />` component. Review the docs for that
   * component to see the available options
   */
  inputProps?: BaseTextareaProps
  /**
   * Text to be displayed as a label above the input
   */
  label?: React.ReactNode
  /**
   * CSS class name to apply to the `<Label />` component
   */
  labelClassName?: string
  /**
   * Object representing props that should be passed down
   * to the `<Label />` component. Review the docs for that
   * component to see the available options
   */
  labelProps?: LabelProps
  /**
   * CSS class name to apply to the `<HelperText />` component
   */
  helperTextClassName?: string
  /**
   * Object representing props that should be passed down
   * to the `<HelperText />` component. Review the docs for that
   * component to see the available options
   */
  helperTextProps?: HelperTextProps
}

type TextareaModeProps =
  | { mode?: undefined; onModeChange?: never }
  | {
      /**
       * The property that allows us to display the textarea
       * value in a view only format in a paragraph tag
       */
      mode: TextAreaModes
      /**
       * Event handler that is required when the `mode` prop is provided.
       * Handler responsible for updating the textarea mode (i.e. view or edit)
       */
      onModeChange: (mode: TextAreaModes) => void
    }

/**
 * These prop definitions are defined following the approach defined in
 * https://www.benmvp.com/blog/conditional-react-props-typescript/ for
 * typing conditional react props
 */
type TextareaProps = CommonTextareaProps & TextareaModeProps

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      className,
      disabled,
      error,
      errorText,
      focus,
      helperText,
      hover,
      fullWidth = false,
      iconLeft,
      iconRight,
      inputProps,
      inputClassName,
      label,
      labelClassName,
      labelProps,
      helperTextClassName,
      helperTextProps,
      mode = 'edit',
      name,
      onBlur,
      onChange,
      onFocus,
      onModeChange,
      preventScrollOnFocus,
      value,
      ...rest
    } = props

    /**
     * Event handler that controls toggling between edit and view mode
     * @param mode {string} either view or edit
     */
    const handleModeChange =
      (mode: TextAreaModes) => (event: React.MouseEvent) => {
        onModeChange(mode)
      }

    /**
     * Wrapper for the blur event
     * If the component is being used in the dual view/edit mode we
     * need to make sure that the mode gets set back to view on blur
     */
    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      onModeChange && onModeChange('view')
      onBlur && onBlur(event)
    }

    if (mode === 'view') {
      return (
        <div className={s.viewModeContainer}>
          <Body size="small">{value}</Body>
          <Edit2Icon
            className={s.editIcon}
            onClick={handleModeChange('edit')}
            size="medium"
            title="Edit"
          />
        </div>
      )
    }

    return (
      <div
        className={className}
        {...(rest as unknown as HTMLAttributes<HTMLDivElement>)}>
        <Label disabled={disabled} className={labelClassName} {...labelProps}>
          {!!label && <span className={s.labelText}>{label}</span>}
          <BaseTextarea
            className={inputClassName}
            disabled={disabled}
            error={error}
            hover={hover}
            focus={focus}
            fullWidth={fullWidth}
            iconLeft={iconLeft}
            iconRight={iconRight}
            name={name}
            onBlur={handleBlur}
            onChange={onChange}
            onFocus={onFocus}
            preventScrollOnFocus={preventScrollOnFocus}
            ref={ref}
            value={value}
            {...inputProps}
          />
        </Label>
        <HelperText
          className={cn(s.helperText, helperTextClassName)}
          error={error}
          errorText={errorText}
          text={helperText}
          {...helperTextProps}
        />
      </div>
    )
  }
)
