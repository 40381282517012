import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import {
  useFloating,
  useInteractions,
  useClick,
  useHover,
  useDismiss,
  useRole,
  UseFloatingProps,
  UseFloatingReturn,
  ReferenceElement,
} from '@floating-ui/react'

import useAnimationFrame from 'hooks/use-animation-frame'

interface MenuContextProps extends UseFloatingReturn<ReferenceElement> {
  open: boolean
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>
}

type PartialFloatingProps = Partial<UseFloatingProps<ReferenceElement>>

interface MenuProps {
  children: React.ReactNode
  /**
   * Optional CSS class name to pass to the root
   * of the component
   */
  className?: string
  /**
   * props accepted by useFloating
   */
  floatingProps: PartialFloatingProps
  /**
   * Whether or not to open menu on hover
   */
  enableHover?: boolean
}

const IS_PROD = process.env.APP_ENV === 'production'

const DEFAULT_FLOATING_PROPS: PartialFloatingProps = {
  strategy: 'fixed',
  placement: 'bottom',
}

export const MenuContext = createContext<MenuContextProps>(null)

export const useMenu = (componentName: string): MenuContextProps => {
  const context = useContext(MenuContext)

  useEffect(() => {
    if (!IS_PROD && !context) {
      console.warn(
        `The <${componentName} /> component should be wrapped by the <Menu /> component`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return context
}

/**
 * Component used to render a Menu
 * The event handlers associated with opening and closing the menu
 * are attached here at the top level
 * You will likely use this component in conjunction with the
 * <MenuButton />, <MenuList />, and <MenuItem /> components
 */
export const Menu = ({
  children,
  className,
  floatingProps,
  enableHover = false,
}: MenuProps) => {
  const floating = useFloating({
    ...DEFAULT_FLOATING_PROPS,
    ...floatingProps,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(floating.context, { enabled: !enableHover }),
    useHover(floating.context, { enabled: enableHover }),
    useDismiss(floating.context),
    useRole(floating.context, { role: 'menu' }),
  ])

  const contextValue = useMemo(
    () => ({ ...floating, getFloatingProps, open: floatingProps.open }),
    [floating, floatingProps.open, getFloatingProps]
  )

  const referencePosition = useRef<{ x: number; y: number }>()

  useAnimationFrame(() => {
    if (floating.refs.reference.current) {
      const { x, y } = floating.refs.reference.current.getBoundingClientRect()

      if (!referencePosition.current) {
        referencePosition.current = { x, y }
        return
      }

      if (
        referencePosition.current.x !== x ||
        referencePosition.current.y !== y
      ) {
        floating.update()
        referencePosition.current = { x, y }
      }
    }
  })

  return (
    <MenuContext.Provider value={contextValue}>
      <div
        className={className}
        ref={floating.refs.setReference}
        {...getReferenceProps()}>
        {children}
      </div>
    </MenuContext.Provider>
  )
}
