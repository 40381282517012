import React, { RefObject } from 'react'
import cn from 'classnames'
import Link from 'next/link'
import cx from 'classnames'

import { withAnalyticsHandler } from 'hoc/with-analytics-handler'
import s from './styles.module.css'

type ButtonIconProps = {
  className?: string
  children?: React.ReactElement
  size?: ButtonSizes
}

export type ButtonSizes = 'small' | 'medium' | 'large'

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'textLight'
  | 'textDark'
  | 'textSecondary'

export interface IconButtonProps {
  /**
   * If set to true, button width will be set to auto
   */
  auto?: boolean
  /**
   * CSS class name to pass to the button container
   */
  className?: string
  children?: React.ReactElement
  /**
   * Whether the button should be in a disabled state
   */
  disabled?: boolean
  /**
   * Optional id to pass to root button
   */
  id?: string
  onClick?: (event: React.MouseEvent) => void
  /**
   * Optional name attribute for the button
   */
  name?: string
  /**
   * Specifies the button size
   */
  size?: ButtonSizes
  /**
   * An optional property that allows the button to function as a link
   */
  to?: string
  type?: 'submit' | 'button' | 'reset' | undefined
  /**
   * The button styling variant
   */
  variant?: ButtonVariants
  /**
   * Optional ref for button
   */
  buttonRef?: RefObject<HTMLButtonElement>
}

/**
 * Lookup object that maps button sizes to the
 * proper icon size based on the design system specs
 * Small and medium sized buttons use the small icon
 * size while the large sized button uses the medium
 * icon size
 */
export const ButtonIconSizeLookup = {
  small: 'small',
  medium: 'medium',
  large: 'large',
}

/**
 * Wrapper component that gets used with the `iconLeft` and
 * `iconRight` props
 * We have some special logic in place here so that we can assign
 * the appropriate icon size to the provided icon based on the button
 * size
 * If we didn't do this, the developer would have to pass an icon
 * size prop with the provided icon every time which could easily
 * lead to inconsistencies throughout the app
 */
const ButtonIcon = ({
  className,
  children,
  size: sizeProp,
}: ButtonIconProps) => {
  const size = children?.props?.size || ButtonIconSizeLookup[sizeProp]
  const Icon = React.cloneElement(children, { size })
  return <span className={className}>{Icon}</span>
}

const IconButtonComponent = ({
  auto = true,
  children,
  className,
  disabled,
  id,
  name,
  onClick,
  buttonRef,
  size = 'medium',
  to,
  type = 'button',
  variant = 'primary',
}: IconButtonProps) => {
  const mappedClassName = cn(s.root, className, {
    [s.auto]: auto,
    [s[size]]: size,
    [s[variant]]: variant,
  })

  if (to) {
    return (
      <Link
        href={to}
        className={mappedClassName}
        onClick={onClick}
        id={id || name}>
        <ButtonIcon className={s.icon} size={size}>
          {children}
        </ButtonIcon>
      </Link>
    )
  }

  return (
    <button
      className={cx(mappedClassName, { [s.disabled]: disabled })}
      disabled={disabled}
      id={id}
      name={name}
      onClick={onClick}
      type={type}
      ref={buttonRef}>
      <ButtonIcon className={s.icon} size={size}>
        {children}
      </ButtonIcon>
    </button>
  )
}

export const IconButton = withAnalyticsHandler(IconButtonComponent, ['Click'])
