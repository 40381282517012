import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import s from './styles.module.css'

type CollapseProps = {
  children?: React.ReactNode
  /**
   * Optional CSS class name to pass to the root component
   */
  className?: string
  /**
   * Boolean indicating whether the component's children should be
   * visible
   */
  visible?: boolean
}

/**
 * A work in progress component that can be used to show and hide
 * content with a nice animation heavily inspired by Material UI
 */
export const Collapse = ({
  children,
  className,
  visible = false,
}: CollapseProps) => {
  const childRef = useRef(null)
  const [childHeight, setChildHeight] = useState(0)

  useEffect(() => {
    const childHeight = visible ? childRef?.current?.clientHeight : 0
    setChildHeight(childHeight)
  }, [childRef, visible])

  return (
    <div
      className={cn(s.rootCollapse, className, { [s.collapsed]: !visible })}
      style={{
        height: childHeight,
      }}>
      <div className={s.collapseChildrenContainer} ref={childRef}>
        {children}
      </div>
    </div>
  )
}
