import cn from 'classnames'
import { ButtonSizes, ButtonVariants } from 'components/design-system/button'

import { BaseTypography, BaseTypographyProps } from '../base-typography'

import s from './styles.module.css'

interface ButtonTextProps extends BaseTypographyProps {
  /**
   * Specify the size variant of the Title component
   */
  size?: ButtonSizes
  /**
   * Optional prop that locks you into either the mobile or desktop
   * type system regardless of the screen size and breakpoint styles
   * The default behavior is to use the mobile type system variant of a size on mobile
   * and then use the desktop type system variant on table and above. This prop
   * provides an escape hatch for that behavior
   */
  typeSystem?: 'mobile' | 'desktop'
  variant?: ButtonVariants
}

export const ButtonText = ({
  className,
  children,
  size = 'large',
  tag = 'span',
  typeSystem,
  weight = 'medium',
  variant,
  ...rest
}: ButtonTextProps) => {
  return (
    <BaseTypography
      className={cn(
        s.buttonTextRoot,
        s[size],
        s[typeSystem],
        s[variant],
        className
      )}
      tag={tag}
      weight={weight}
      {...rest}>
      {children}
    </BaseTypography>
  )
}
