import { Button } from 'components/design-system'
import s from './styles.module.css'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'components/design-system/kickoff-icons'
import { useSafeAreaInsets } from 'hooks/use-safe-area-insets'

interface StickyFooterProps {
  backDisabled?: boolean
  backLabel?: string
  backLink?: string
  onBackClick: () => void
  nextDisabled?: boolean
  nextLabel?: string
  nextLink?: string
  onNextClick: () => void
}

export const StickyFooter = ({
  backDisabled,
  backLabel = 'Back',
  backLink,
  onBackClick,
  nextLink,
  nextLabel = 'Next',
  nextDisabled,
  onNextClick,
}: StickyFooterProps) => {
  const insets = useSafeAreaInsets()

  return (
    <div
      className={s.container}
      style={{ paddingBottom: Math.max(insets.bottom, 16) }}>
      <Button
        className={s.backButton}
        disabled={backDisabled}
        onClick={onBackClick}
        variant="secondary"
        size="medium"
        iconLeft={<ArrowLeftIcon />}
        to={backLink}>
        {backLabel}
      </Button>
      <Button
        className={s.nextButton}
        onClick={onNextClick}
        variant="primary"
        size="medium"
        disabled={nextDisabled}
        iconRight={nextLabel === 'Done' ? '' : <ArrowRightIcon />}
        to={nextLink}>
        {nextLabel}
      </Button>
    </div>
  )
}
