import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import * as R from 'ramda'

import { ClearIcon } from 'components/design-system/kickoff-icons'
import s from './styles.module.css'
import colors from 'styles/colors.module.css'
import { Hidden } from 'components/design-system'

export interface BaseInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Whether the form control is in an error state
   */
  error?: boolean
  /**
   * Boolean indicating whether the clear "x" icon should be shown
   */
  excludeClearIcon?: boolean
  /**
   * Escape hatch for controlling whether the input should be
   * shown in a focus state
   */
  focus?: boolean
  /**
   * Escape hatch for controlling whether the input should be
   * shown in a hover state
   */
  hover?: boolean
  /**
   * Whether the input should fully grow into the
   * available space
   */
  fullWidth?: boolean
  /**
   * Icon to be rendered on the left-side of the input
   */
  iconLeft?: React.ReactElement
  /**
   * Icon to be rendered on the right-side of the input
   */
  iconRight?: React.ReactElement
  /**
   * Optional CSS class name to pass to the right icon
   */
  iconRightClassName?: string
  /**
   * Optional CSS class name to pass to the actual
   * input element
   */
  innerInputClassName?: string
  /**
   * Event handler for the input blur event
   */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  /**
   * Event handler for the input change event
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Event handler for the input focus event
   */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  /**
   * Whether the default scroll to element on focus behavior
   * should be enabled
   */
  preventScrollOnFocus?: boolean
  /**
   * CSS class name to apply while focused
   */
  focusClassName?: string
  /**
   * CSS class name to apply while hovered
   */
  hoverClassName?: string
}

type InputIconProps = {
  children?: React.ReactElement
  className?: string
  onClick?: (event: React.MouseEvent) => void
  size?: 'small' | 'medium' | 'large'
}

/**
 * Wrapper component that gets used with the `iconLeft` and
 * `iconRight` props
 * We have some special logic in place here so that we can assign
 * the appropriate icon size to the provided icon
 * If we didn't do this, the developer would have to pass an icon
 * size prop with the provided icon every time which could easily
 * lead to inconsistencies throughout the app
 */
const InputIcon = ({
  className,
  children,
  onClick,
  size: sizeProp,
}: InputIconProps) => {
  const size = children?.props?.size || sizeProp
  const Icon = React.cloneElement(children, { size })
  return (
    <span className={className} onClick={onClick}>
      {Icon}
    </span>
  )
}

/**
 * The BaseInput component is meant to serve as a bare bones
 * but highly configurable input component for all open field
 * input types (i.e. think email, number, url, tel, text, etc)
 */
export const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>(
  function BaseInput(props, ref) {
    const {
      autoFocus,
      className,
      disabled,
      error,
      excludeClearIcon = false,
      focus,
      hover,
      fullWidth = false,
      iconLeft,
      iconRight,
      iconRightClassName,
      innerInputClassName: providedInnerInputClassName,
      focusClassName,
      hoverClassName,
      onBlur,
      onChange,
      onFocus,
      preventScrollOnFocus = false,
      type = 'text',
      value,
      ...rest
    } = props

    const baseRef = useRef<HTMLInputElement | null>(null)
    const inputRef = ref ? ref : baseRef
    const [focused, setFocused] = useState(autoFocus || focus)
    const isControlled = !R.isNil(value) && !!onChange

    /**
     * Currently necessary to include this use effect
     * to make the handleClear functionality work
     */
    useEffect(() => {
      setFocused(focus)
      return () => {}
    }, [focus])

    /**
     * Also kind of a hacky workaround to ensure that
     * autoFocus takes precendence on render
     */
    useEffect(() => {
      setFocused(autoFocus)
      return () => {}
    }, [autoFocus])

    /**
     * This useEffect hook is necessary to properly focus
     * and blur the input
     * Because the element that is actually being styled to look
     * like an input is not actually an input (because we need to support
     * left and right icons), we need to programmatically kick off
     * focus and blur events whenever the `focused` state variable
     * changes
     * An alternative approach could be to add a custom click away listener
     * that listens for clicks outside of the parent div in this component
     */
    useEffect(() => {
      if (focused) {
        baseRef.current.focus({ preventScroll: preventScrollOnFocus })
      } else {
        baseRef.current.blur()
      }
    }, [baseRef, focused, preventScrollOnFocus])

    /**
     * Define supplement state based class names for the input
     */
    const inputStateClassNames = {
      [s.disabled]: disabled,
      [s.error]: error,
      [s.hover]: hover,
      [hoverClassName]: hover,
      [s.focus]: focused || focus,
      [focusClassName]: focused || focus,
      [s.fullWidth]: fullWidth,
    }

    const inputClassName = cn(s.inputRoot, className, inputStateClassNames)
    const innerInputClassName = cn(
      s.inputInner,
      inputStateClassNames,
      providedInnerInputClassName
    )

    /**
     * Event handler used to clear the input when
     * the user clicks on the clear icon that is exposed
     * in the focus state
     */
    const handleClear = e => {
      if (!isControlled) {
        baseRef.current.value = ''
      } else {
        handleChange({
          target: {
            value: '',
          },
        })
      }
      setFocused(true)
    }

    const handleFocus = e => {
      onFocus && onFocus(e)
      setFocused(true)
    }

    /**
     * Event handler for the blur event
     * Currently utilizing a somewhat janky implementation where
     * we set focused to false and delay the blur event from firing
     * so that the handleClear event can be fired off before the blur event
     * @param {*} e
     */
    const handleBlur = e => {
      // TODO find workaround
      setTimeout(() => {
        onBlur && onBlur(e)
        setFocused(false)
      }, 150)
    }

    const handleChange = e => {
      onChange && onChange(e)
    }

    return (
      <div className={inputClassName} onClick={handleFocus}>
        {iconLeft && (
          <InputIcon className={s.iconLeft} size="medium">
            {iconLeft}
          </InputIcon>
        )}
        <input
          autoFocus={autoFocus}
          className={innerInputClassName}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          ref={e => {
            typeof inputRef === 'function' && inputRef(e)
            baseRef.current = e
          }}
          type={type}
          value={value}
          {...rest}
        />

        <Hidden visible={!excludeClearIcon}>
          <InputIcon
            className={focused || focus ? s.iconClear : s.iconClearHidden}
            onClick={focused || focus ? handleClear : undefined}>
            <ClearIcon
              color={focused ? colors.NEUTRAL_GRAY_300 : 'none'}
              stroke={focused ? '#fff' : 'none'}
            />
          </InputIcon>
        </Hidden>

        {iconRight && (
          <InputIcon
            className={cn(s.iconRight, iconRightClassName)}
            size="medium">
            {iconRight}
          </InputIcon>
        )}
      </div>
    )
  }
)
