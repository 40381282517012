const timeoutCallback = callback => setTimeout(callback, 1000 / 60)
const w = typeof window === 'undefined' ? {} : window

const requestAnimFrame =
  w.requestAnimationFrame ||
  w.webkitRequestAnimationFrame ||
  w.mozRequestAnimationFrame ||
  timeoutCallback

export { requestAnimFrame }
