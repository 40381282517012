import { createContext, ReactNode, useContext, useMemo } from 'react'

import { EventProperties } from 'types/analytics'

interface AnalyticsSectionInterface {
  name: string
  properties: EventProperties
}

const AnalyticsSectionContext = createContext<AnalyticsSectionInterface | null>(
  null
)

export const useAnalyticsSection = () => useContext(AnalyticsSectionContext)

interface Props {
  name: string
  properties?: EventProperties
  children: ReactNode
}

export const AnalyticsSection = ({
  name,
  properties = {},
  children,
}: Props) => {
  const contextValue = useMemo(() => ({ name, properties }), [name, properties])

  return (
    <AnalyticsSectionContext.Provider value={contextValue}>
      {children}
    </AnalyticsSectionContext.Provider>
  )
}
