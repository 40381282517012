import React, { useRef, useState } from 'react'
import cn from 'classnames'
import {
  RadioCheckedIcon,
  RadioUncheckedIcon,
} from 'components/design-system/kickoff-icons'
import s from './styles.module.css'
import colors from 'styles/colors.module.css'

type RadioOptionProps = {
  checked?: boolean
  className?: string
  disabled?: boolean
  focus?: boolean
  hover?: boolean
  iconClassName?: string
  label: React.ReactNode
  subText?: React.ReactNode
  labelClassName?: string
  subTextClassName?: string
  name?: string
  onBlur?: (value: string | number) => void
  onChange: (value: string | number) => void
  onFocus?: (value: string | number) => void
  onKeyDown?: (value: string | number) => void
  value: string | number
}

type RadioOptionCircleProps = {
  checked?: boolean
  className?: string
  disabled?: boolean
  onBlur?: (value: string | number) => void
  onChange: (value: string | number) => void
  onFocus?: (value: string | number) => void
  value: string | number
}

const setIcon = (checked: boolean, disabled: boolean): React.ReactNode => {
  if (checked && disabled) {
    return <RadioCheckedIcon color={colors.NEUTRAL_GRAY_500} />
  } else if (checked && !disabled) {
    return <RadioCheckedIcon />
  } else if (disabled) {
    return <RadioUncheckedIcon color={colors.NEUTRAL_GRAY_500} />
  }
  return <RadioUncheckedIcon />
}

const RadioOptionCircle = React.forwardRef<
  HTMLInputElement,
  RadioOptionCircleProps
>(function RadioOptionCircle(props, ref) {
  const { checked, className, disabled, onChange, value } = props
  const baseRef = useRef<HTMLInputElement>(null)
  const radioOptionRef = ref ? ref : baseRef
  const icon = setIcon(checked, disabled)
  return (
    <span className={cn(s.radioOptionCircleRoot, className)}>
      <input
        className={s.radioOptionCircleInput}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(value)}
        ref={radioOptionRef}
        tabIndex={-1}
        type="radio"
        value={value}
        id={props.id || value}
      />
      <span className={s.radioOptionCircleIcon}>{icon}</span>
    </span>
  )
})

export const RadioOption = React.forwardRef<HTMLInputElement, RadioOptionProps>(
  function RadioOption(props, ref) {
    const {
      checked,
      className,
      disabled,
      focus,
      hover,
      iconClassName,
      label,
      subText,
      labelClassName,
      subTextClassName,
      onChange,
      onKeyDown,
      value,
      id,
    } = props
    const [focused, setFocused] = useState(focus)

    const handleFocus = () => {
      setFocused(true)
    }

    const handleBlur = () => {
      setFocused(false)
    }

    const handleKeyDown = (
      event: React.KeyboardEvent<HTMLLabelElement>,
      val: string | number
    ) => {
      if (event?.key?.toLowerCase() === 'enter') {
        onKeyDown && onKeyDown(val)
        onKeyDown ? onKeyDown(val) : onChange && onChange(val)
      }
    }

    const mappedClassName = cn(s.radioOptionRoot, className, {
      [s.disabled]: disabled,
      [s.focus]: focused || focus,
      [s.hover]: hover,
    })

    return (
      <label
        className={mappedClassName}
        onKeyDown={e => handleKeyDown(e, value)}
        tabIndex={disabled ? -1 : 0}>
        <RadioOptionCircle
          className={iconClassName}
          checked={checked}
          disabled={disabled}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={handleFocus}
          ref={ref}
          value={value}
          id={id}
        />
        <div className={s.radioOptionLabelWrapper}>
          <div className={labelClassName}>{label}</div>
          {!!subText && <div className={subTextClassName}>{subText}</div>}
        </div>
      </label>
    )
  }
)
