import cn from 'classnames'

import { BaseTypography, BaseTypographyProps } from '../base-typography'

import s from './styles.module.css'

interface TitleProps extends BaseTypographyProps {
  /**
   * Specify the size variant of the Title component
   */
  size?:
    | 'xxxsmall'
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
  /**
   * Optional prop that locks you into either the mobile or desktop
   * type system regardless of the screen size and breakpoint styles
   * The default behavior is to use the mobile type system variant of a size on mobile
   * and then use the desktop type system variant on tablet and above. This prop
   * provides an escape hatch for that behavior
   */
  typeSystem?: 'mobile' | 'desktop'
}

const sizeToTagLookup = {
  xlarge: 'h2',
  large: 'h3',
  medium: 'h4',
  small: 'h5',
  xsmall: 'h6',
  xxsmall: 'h6',
  xxxsmall: 'h6',
}

export const Title = ({
  className,
  children,
  size = 'xlarge',
  tag,
  uppercase,
  typeSystem,
  weight = 'semibold',
  ...rest
}: TitleProps) => {
  const mappedTag = tag || (sizeToTagLookup[size] as React.ElementType)
  return (
    <BaseTypography
      className={cn(
        s[size],
        { [s.uppercase]: uppercase },
        s[typeSystem],
        className
      )}
      tag={mappedTag}
      weight={weight}
      uppercase={uppercase}
      {...rest}>
      {children}
    </BaseTypography>
  )
}
