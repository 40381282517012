import React, { useRef, useState } from 'react'
import cn from 'classnames'
import {
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
} from 'components/design-system/kickoff-icons'
import { HelperText, HelperTextProps } from '../helper-text'
import s from './styles.module.css'
import colors from 'styles/colors.module.css'
import { BaseInputProps } from '../base-input'

/**
 * Utility function used to return the proper
 * checkbox icon based on the checkbox
 * checked and disabled status
 * @param {boolean} checked whether the checkbox is checked
 * @param {boolean} disabled whether the checkbox is disabled
 * @returns {string} returns the path to the appropriate icon
 * based on the checkbox state
 */
const setIcon = (checked, disabled) => {
  if (checked && disabled) {
    return <CheckboxCheckedIcon color={colors.NEUTRAL_GRAY_500} />
  } else if (checked && !disabled) {
    return <CheckboxCheckedIcon />
  } else if (disabled) {
    return <CheckboxUncheckedIcon color={colors.NEUTRAL_GRAY_500} />
  }
  return <CheckboxUncheckedIcon />
}

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Whether the form control is in an error state
   */
  error?: boolean
  /**
   * Text that should be displayed when the form control
   * is in an error state
   */
  errorText?: React.ReactNode
  /**
   * Helper text that should can provide additional hints for
   * the form control
   */
  helperText?: React.ReactNode
  /**
   * Escape hatch for controlling whether the input should be
   * shown in a hover state
   */
  /**
   * CSS class name to apply to the `<HelperText />` component
   */
  helperTextClassName?: string
  /**
   * Object representing props that should be passed down
   * to the `<HelperText />` component. Review the docs for that
   * component to see the available options
   */
  helperTextProps?: HelperTextProps
  /**
   * Whether the input should be shown in a hover state
   */
  hover?: boolean
  /**
   * Whether the input should be shown in a focus state
   */
  focus?: boolean
  /**
   * CSS class name to apply to the `<BaseInput />` component
   */
  inputClassName?: string
  /**
   * CSS class name to apply to the label text
   */
  labelClassName?: string
  /**
   * Object representing props that should be passed down
   * to the `<BaseInput />` component. Review the docs for that
   * component to see the available options
   */
  inputProps?: BaseInputProps
  /**
   * Label to display alongside the checkbox input
   */
  label?: string
}

export const CheckboxRoot = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      checked,
      className,
      labelClassName,
      disabled,
      focus,
      hover,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      value,
      ...rest
    } = props
    const baseRef = useRef(null)
    const checkboxRef = ref ? ref : baseRef
    const icon = setIcon(checked, disabled)

    const checkboxRootClassName = cn(s.checkboxBaseRoot, className, {
      [s.checked]: checked,
      [s.disabled]: disabled,
      [s.hover]: hover,
      [s.focus]: focus,
    })

    /**
     * Handle the keydown so that a user can tab to focus
     * the checkbox and hit enter to select and unselect it
     * @param {object} event event object
     */
    const handleKeyDown = event => {
      if (event?.key?.toLowerCase() === 'enter') {
        const mockEvent = {
          ...event,
          target: {
            ...event?.target,
            checked: !checked,
          },
        }
        onKeyDown ? onKeyDown(mockEvent) : onChange && onChange(mockEvent)
      }
    }

    return (
      <span
        className={checkboxRootClassName}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={handleKeyDown}
        tabIndex={disabled ? -1 : 0}>
        {icon}
        <input
          checked={checked}
          className={cn(s.checkboxInner, className)}
          disabled={disabled}
          name={name}
          onChange={onChange}
          ref={checkboxRef}
          tabIndex={-1}
          type="checkbox"
          value={value}
          {...rest}
        />
      </span>
    )
  }
)

/**
 * Component used to render a checkbox control that
 * displays a checkbox and label
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      checked,
      className,
      labelClassName,
      disabled,
      error,
      errorText,
      focus,
      helperText,
      helperTextClassName,
      helperTextProps,
      hover,
      inputProps,
      inputClassName,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown = onChange,
      value,
      ...rest
    } = props
    const [focused, setFocused] = useState(focus)

    const handleFocus = event => {
      setFocused(true)
      onFocus && onFocus(event)
    }

    const handleBlur = event => {
      setFocused(false)
      onBlur && onBlur(event)
    }

    const handleChange = event => {
      onChange && onChange(event)
    }

    if (label) {
      const checkboxClassName = cn(s.checkboxRoot, className, {
        [s.checked]: checked,
        [s.disabled]: disabled,
        [s.error]: error,
        [s.hover]: hover,
        [s.focus]: focused || focus,
      })

      return (
        <div>
          <label className={checkboxClassName}>
            <CheckboxRoot
              className={cn(s.checkboxRootIcon, inputClassName)}
              checked={checked}
              disabled={disabled}
              focus={focus}
              hover={hover}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onKeyDown={
                onKeyDown as React.KeyboardEventHandler<HTMLInputElement>
              }
              ref={ref}
              value={value}
              {...inputProps}
            />
            <span className={labelClassName || s.checkboxLabel}>{label}</span>
          </label>
          <HelperText
            className={cn(s.helperText, helperTextClassName)}
            error={error}
            errorText={errorText}
            text={helperText}
            {...helperTextProps}
          />
        </div>
      )
    }

    return (
      <CheckboxRoot
        checked={checked}
        className={className}
        disabled={disabled}
        focus={focus}
        hover={hover}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={onKeyDown as React.KeyboardEventHandler<HTMLInputElement>}
        ref={ref}
        value={value}
        {...rest}
      />
    )
  }
)

export default Checkbox
